<template>
	<el-drawer
		title="查看客户方案信息"
		:visible.sync="drawer_"
		destroy-on-close
		direction="rtl"
		custom-class="demo-drawer"
		ref="drawer"
		size="70%"
		@open="open"
		@close="
			() => {
				$emit('closeVisible');
			}
		"
	>
		<div class="demo-drawer__content" style="width: 100%">
			<div class="edit" style="padding: 0 30px; width: 100%">
				<el-card class="box-card">
					<div
						class="desc"
						:class="{
							activeColor:
								item.schemeStatus == 4 ||
								item.schemeStatus == 5,
						}"
					>
						{{
							item.schemeStatus == 4 || item.schemeStatus == 5
								? '进行中'
								: '已完结'
						}}
					</div>
					<div style="position: relative">
						<p
							@click="showDialogVisible(item)"
							class="content-title"
							style="
								margin: 10px;
								font-size: 22px;
								font-weight: 800;
								color: #23924f;
								margin: 0 0 20px 0;
								cursor: pointer;
							"
						>
							{{ item.clientName }}
						</p>
					</div>

					<div style="padding: 0 10px">
						<p class="content-title">需求信息</p>
						<div class="content-box">
							<div>
								<p>客户名称：{{ item.clientName }}</p>
								<p>贷款类型：{{ item.loanTypeName }}</p>
								<p>
									期望利率：{{ item.minExpectRate }} 至
									{{ item.maxExpectRate }}
								</p>
								<p>
									需求金额(万元)：{{ item.minFinancing }} 至
									{{ item.maxFinancing }}
								</p>
							</div>
							<div>
								<p>
									时效要求(天)：{{
										item.minPrescriptionRequirement
									}}
									至 {{ item.maxPrescriptionRequirement }}
								</p>
								<p>
									贷款期限(月)：{{ item.minLoanTerm }} 至
									{{ item.maxLoanTerm }}
								</p>
								<p>还款来源：{{ item.repaymentSource }}</p>
								<p>贷款用途：{{ item.paymentPurpose }}</p>
							</div>
						</div>
					</div>
					<div style="padding: 0 10px">
						<p class="content-title">方案信息</p>
						<div class="content-box">
							<div>
								<p>产品名称：{{ item.financialProductName }}</p>
								<p>
									融资金额(万元)：{{ item.schemeAmountMin }}
								</p>
								<!-- <p>
									融资金额(max)(万元)：{{
										item.schemeAmountMax
									}}
								</p> -->
								<p>利率/费率(%)：{{ item.interestRateMin }}</p>
								<!-- <p>
									利率/费率最大值(%)：{{
										item.interestRateMax
									}}
								</p> -->
								<p>
									银行其他希望条件：{{ item.bankOtherHopes }}
								</p>
								<p>融资期限(月)：{{ item.loanDeadline }}</p>
							</div>
							<div>
								<p>还款方式：{{ item.repaymentMethod }}</p>
								<p>
									融资方案概述：{{
										item.financingPlanSummary
									}}
								</p>
								<p>
									担保条件概述：{{
										item.guaranteeConditionsSummary
									}}
								</p>
								<p>
									银行其他硬性要求：{{
										item.bankOtherRequirements
									}}
								</p>
							</div>
						</div>
					</div>
					<div style="position: relative">
						<myStateItem :item="item" :stateList="stateList" />
						<div style="display: flex; justify-content: flex-end">
							<el-button
								type="primary"
								v-if="
									item.schemeStatus == 4 ||
									item.schemeStatus == 5
								"
								@click="editData(item, 'stateVisible')"
								>方案流转到下一环节
							</el-button>
							<el-button
								type="primary"
								@click="editData(item, 'editVisible')"
								v-if="
									item.schemeStatus == 4 ||
									item.schemeStatus == 5
								"
								>修改
							</el-button>
							<el-button type="primary" @click="feedback(item)"
								>反馈进度及意见
							</el-button>
						</div>
					</div>
				</el-card>
				<div class="demo-drawer__footer">
					<el-button @click="$emit('closeVisible')">关 闭</el-button>
				</div>
			</div>
		</div>
		<stateChange
			class="stateChange"
			@success="success"
			:stateList="stateList"
			:item="params"
			:stateVisible.sync="stateVisible"
			:SchemeFailReasonTypeList="SchemeFailReasonTypeList"
		/>
		<editDrawer
			@successVisible="editSuccess"
			@closeVisible="editVisible = false"
			:params="params"
			:editVisible.sync="editVisible"
		/>
		<publicReply :paramsId="params" :replyVisible.sync="replyVisible" />
		<publicInfoDrawer
			:allData="allData"
			:privacyType="privacyType"
			:dialogVisible.sync="publicDialogVisible"
			:paramsId="paramsId"
			@closeVisible="publicDialogVisible = false"
		/>
	</el-drawer>
</template>

<script>
import { itemByType } from '@/api/index.js';
import myStateItem from '../../scheme/module/stateItem.vue';
import { myMixin } from '@/mixins';
import stateChange from '../../scheme/module/stateChange.vue';
import editDrawer from '../../scheme/module/editDrawer.vue';

export default {
	name: 'schemeDrawer',
	props: {
		schemeVisible: {
			type: Boolean,
			required: true,
		},
		item: {
			type: Object,
			required: true,
		},
	},
	mixins: [myMixin],

	data() {
		return {
			stateList: [],
			stateVisible: false,
			editVisible: false,
			replyVisible: false,
			params: {},
			SchemeFailReasonTypeList: [],
		};
	},
	methods: {
		success(data) {
			this.params.schemeLink = data.schemeLink;
			this.params.schemeStatus = data.schemeStatus;
			this.$emit('changeList', data);
			this.stateVisible = false;
		},
		editSuccess(obj) {
			this.$emit('replaceList', obj);

			this.editVisible = false;
		},
		async getSchemeStatus() {
			const res = await itemByType({
				dictionaryType: 'SchemeLink',
			});
			if (res.returncode === 0) {
				this.stateList = res.data;
			}
		},
		async getSchemeFailReasonTypeList() {
			const res = await itemByType({
				dictionaryType: 'SchemeFailReasonType',
			});
			if (res.returncode === 0) {
				this.SchemeFailReasonTypeList = res.data.map((item) => ({
					value: item.itemValue,
					label: item.itemName,
				}));
			}
		},
		feedback(item) {
			this.params = item;
			this.params.businessType = 9;

			this.replyVisible = true;
		},
		editData(item, Visible) {
			this.params = item;
			this[Visible] = true;
		},
		fn(str = '') {
			return str.replace(/\n/g, '<br />');
		},
		open() {
			this.getSchemeStatus();
			this.getSchemeFailReasonTypeList();
		},
	},
	created() {},
	components: {
		myStateItem,
		stateChange,
		editDrawer,
	},
	computed: {
		drawer_: {
			get() {
				return this.schemeVisible;
			},
			set(v) {
				this.$emit('update:schemeVisible', v);
			},
		},
	},
};
</script>
<style lang="scss" scoped>
.box-card {
	margin-bottom: 10px;
}
::v-deep .demo-drawer__footer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding-bottom: 20px;
	.el-button {
		width: 15%;
	}
}

.inquiry {
	width: 100%;
	background: #fff;
	overflow: auto;
	position: relative;
}
.info {
	line-height: 20px;
	margin: 10px 15px 20px 15px;
}
.desc {
	position: absolute;
	top: -53px;
	right: -78px;
	z-index: 5;
	color: #fff;
	background: #409eff;
	display: block;
	transform: rotateZ(45deg);
	width: 180px;
	transform-origin: bottom left;
	height: 30px;
	text-align: center;
	line-height: 30px;
}
.activeColor {
	background: #f64c4c;
}
.box-card {
	position: relative;
}
</style>
<style lang="scss" scoped>
div {
	box-sizing: border-box;
}
.scheme {
	width: 100%;
	background: #fff;
	position: relative;
}
.content-title {
	color: #000;
	font-weight: 800;
}
.content-box {
	display: flex;
	padding: 0 10px;
	div {
		flex: 1;
	}
	p {
		font-size: 14px;
		color: #888;
		margin: 20px 10px;
	}
}
.desc {
	position: absolute;
	top: -53px;
	right: -78px;
	z-index: 5;
	color: #fff;
	background: #409eff;
	display: block;
	transform: rotateZ(45deg);
	width: 180px;
	transform-origin: bottom left;
	height: 30px;
	text-align: center;
	line-height: 30px;
}
.activeColor {
	background: #f64c4c;
}
.box-card {
	position: relative;
}
</style>
